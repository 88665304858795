import { css } from '@emotion/react';
import React, { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { globalGrey } from '~/modules/AppLayout/Colors';
import { fontWeight500, fontWeight600 } from '~/css/font';
import { ai, flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { useMount, useUnmount } from 'react-use';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { getQuoteTextColor } from '~/modules/AppLayout/colorUtils';
import { useThemeOfParent } from '~/components/theme/useThemeOfParent';
const titleCss = css `
  font-size: 14px;
  ${fontWeight500};
  margin: 4px;
  &.dark {
    color: ${globalGrey.g200};
  }
  &.light {
    color: #454545;
  }
`;
const OhlcTitle = memo(function OhlcTitle(props) {
    const { isPhone } = useMedia();
    const theme = useThemeOfParent().themeKey;
    const sectionCss = css `
    ${flex.h.crossCenter}
    color: ${getQuoteTextColor(props.changePercent, theme === 'dark')};
    margin-left: ${isPhone ? 6 : 12}px;
  `;
    return (<div css={sectionCss}>
      <div css={titleCss} className={theme}>
        {props.text}
      </div>
      <div>{props.price}</div>
      <div>{isNaN(props.changePercent) ? '' : `(${props.changePercent}%)`}</div>
    </div>);
});
export const SignalrCandleTooltipQuote = memo(function SignalrOptionRefQuote(props) {
    const { isPc, isPhone } = useMedia();
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const theme = useThemeOfParent().themeKey;
    const fill = theme === 'dark' ? globalGrey.g50 : '#353535';
    return (<div css={css `
        ${flex.wrap.default}
        ${jc.flexEnd}
        width: 100%;
        ${ai.center}
        padding: 4px;
        color: ${fill};
        & p {
          ${fontWeight600};
          margin: 0;
          font-size: ${isPc ? 16 : 14}px;
        }
      `}>
      <div css={css `
          font-size: 20px;
          ${fontWeight600};
        `}>
        <SymbolName symbol={props.symbol}></SymbolName>
      </div>
      <OhlcTitle text='開' price={symbolInfo?.open || 0} changePercent={quoteChanges?.openChangePercent}/>
      <OhlcTitle text='高' price={symbolInfo?.high || 0} changePercent={quoteChanges?.highChangePercent}/>
      <OhlcTitle text='低' price={symbolInfo?.low || 0} changePercent={quoteChanges?.lowChangePercent}/>
      <OhlcTitle text='收' price={symbolInfo?.close || 0} changePercent={quoteChanges?.closeChangePercent}/>
      <OhlcTitle text='量' price={symbolInfo?.volume || 0} changePercent={NaN}/>
    </div>);
});
