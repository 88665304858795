import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
const weightURL = apirc.stock.stockWeightURL;
const unifyTableURL = apirc.unclassified.unifyTableURL;
export const useStockWeightResource = (exchange) => {
    // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
    const swr = useSWR(urlcat(weightURL + '/', { exchange }));
    const data = swr.data;
    return data ? Object.entries(data) : [];
};
export const useUnifyTableResource = (from, to) => {
    const swr = useSWR(
    // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
    urlcat(unifyTableURL + '/', {
        from,
        to,
    }), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    return swr.data;
};
